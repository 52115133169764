<template>
    <section class="detail-setup-quiz">
        <div class="container-fluid text-left">
            <div class="row">
                <div class="col-3">
                    <div class="head-card">
                        <div class="back d-flex mb-3">
                            <div class="d-flex" @click="$router.go(-1)" type="button">
                                <img src="@/assets/images/icons/back-icon.svg" alt="">
                                <h3 class="ml-2">Kembali</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="txt-nama-quiz">Nama Quiz</label>
                                <input type="text" class="form-control" id="txt-nama-quiz">
                            </div>
                            <div class="form-group">
                                <label for="txt-deskripsi-quiz">Quiz Description</label>
                                <textarea class="form-control" id="txt-deskripsi-quiz" rows="4"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="txt-deskripsi-quiz">Tambahkan Gambar quiz</label>
                                <div class="d-flex">
                                    <div class="container-image">
                                        <img src="@/assets/images/background/change-image.svg" alt="" class="img-form">
                                    </div>
                                    <small class="my-auto ml-2">gambar1.jpg</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="dd-grade">Grade</label>
                                <select name="grade" id="dd-grade" class="form-control select-badge">
                                    <option value="">Elementary (1 - 2 SD)</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="dd-subject">Subjek</label>
                                <select name="subject" id="dd-subject" class="form-control select-badge">
                                    <option value="">Matematika</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="dd-learning">Mata Pelajaran</label>
                                <select name="learning" id="dd-learning" class="form-control select-badge">
                                    <option value="">Pecahan</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <h3>Import Pertanyaan</h3>
                            <h5 for="txt-deskripsi-quiz" class="my-2 font-weight-normal">Impor pertanyaan dari spreadsheet/excel</h5>
                            <p class="text-none">Harap unggah spreadsheet/excel yang mengikuti contoh template.</p>
                            <div class="excel d-flex mt-2 align-items-center mb-2">
                                <img src="@/assets/images/logo/excel-logo.png" alt="" class="logo-excel">
                                <p class="m-0 ml-2">Unduh Contoh Format</p>
                            </div>
                            <button class="btn btn-warning text-white btn-impor my-3"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                        </div>
                    </div>
                    <button class="btn btn-success btn-lg btn-block text-white my-3" @click="publishQuiz">SIMPAN PERUBAHAN</button>
                </div>
                <div class="col-9">
                    <div class="head-card">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="question-total d-flex align-items-center">
                                <font-awesome-icon icon="fa-regular fa-file-alt" />
                                <h3 class="font-weight-normal ml-2 mr-auto">4 Pertanyaan</h3>
                            </div>
                            <div>
                                <button type="button" class="btn btn-primary btn-block my-2 text-center" @click="tambahPertanyaan"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Tambahkan Pertanyaan</button>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-pg">
                        <div class="card">
                            <div class="card-body py-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="col-md-6">
                                        <h5 class="font-weight-normal">1. Pilihan Ganda</h5>
                                    </div>
                                    <div class="col-md-6 d-flex">
                                        <select name="" id="poin" class="form-control mr-3">
                                            <option value="">10 Points</option>
                                        </select>
                                        <select name="" id="time" class="form-control mr-3">
                                            <option value="">30 Seconds</option>
                                        </select>
                                        <button type="button" class="btn btn-outline-secondary mr-3 d-flex align-items-center"><img src="@/assets/images/icons/Edit.svg" alt="" class="mr-1"><span class="text-dark">Edit</span></button>
                                        <button type="button" class="btn btn-outline-secondary"><font-awesome-icon color="#6D75F6" icon="fa-regular fa-trash-can" class="text-dark" /></button>
                                    </div>
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="d-flex w-100">
                                        <div class="container-image" style="width: 8%">
                                            <img src="@/assets/images/soekarno.png" alt="" class="img-form">
                                        </div>
                                        <p class="font-weight-normal my-auto ml-2">Siapakah tokoh yang ada pada gambar disamping?</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p class="text-secondary mb-0">Answers</p>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="answer">
                                        <table>
                                            <tr>
                                                <td class="pr-5">
                                                    <vs-radio color="danger" class="answer-question-pg justify-content-start" vs-value="luis">A. Dilan</vs-radio>
                                                </td>
                                                <td class="pr-5">
                                                    <vs-radio color="danger" class="answer-question-pg justify-content-start" vs-value="carols">C. BJ. Habibi</vs-radio>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pr-5">
                                                    <vs-radio color="danger" class="answer-question-pg justify-content-start" vs-value="summer">B. Engkus</vs-radio>
                                                </td>
                                                <td class="pr-5">
                                                    <vs-radio color="success" class="answer-question-pg justify-content-start" vs-value="lyon">D. Ir. Soekarno</vs-radio>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-essai">
                        <div class="card">
                            <div class="card-body py-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="col-md-6">
                                        <h5 class="font-weight-normal">2. Essai</h5>
                                    </div>
                                    <div class="col-md-6 d-flex">
                                        <select name="" id="poin" class="form-control mr-3">
                                            <option value="">10 Points</option>
                                        </select>
                                        <select name="" id="time" class="form-control mr-3">
                                            <option value="">30 Seconds</option>
                                        </select>
                                        <button type="button" class="btn btn-outline-secondary mr-3 d-flex align-items-center"><img src="@/assets/images/icons/Edit.svg" alt="" class="mr-1"><span class="text-dark">Edit</span></button>
                                        <button type="button" class="btn btn-outline-secondary"><font-awesome-icon color="#6D75F6" icon="fa-regular fa-trash-can" class="text-dark" /></button>
                                    </div>
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="d-flex w-100">
                                        <p class="font-weight-normal my-auto">Apa yang dimaksud dengan Go Green? Berikan contohnya!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-singkat">
                        <div class="card">
                            <div class="card-body py-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="col-md-6">
                                        <h5 class="font-weight-normal">3. Short Answers</h5>
                                    </div>
                                    <div class="col-md-6 d-flex">
                                        <select name="" id="poin" class="form-control mr-3">
                                            <option value="">10 Points</option>
                                        </select>
                                        <select name="" id="time" class="form-control mr-3">
                                            <option value="">30 Seconds</option>
                                        </select>
                                        <button type="button" class="btn btn-outline-secondary mr-3 d-flex align-items-center"><img src="@/assets/images/icons/Edit.svg" alt="" class="mr-1"><span class="text-dark">Edit</span></button>
                                        <button type="button" class="btn btn-outline-secondary"><font-awesome-icon color="#6D75F6" icon="fa-regular fa-trash-can" class="text-dark" /></button>
                                    </div>
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="d-flex w-100">
                                        <p class="font-weight-normal my-auto">Apa yang dimaksud dengan Go Green? Berikan contohnya!</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p class="text-secondary mb-0">Jawaban</p>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="answer">
                                        <div class="d-flex w-100">
                                            <vs-radio color="#D9D9D9" class="mr-auto" vs-value="lyon">Ir. Soekarno</vs-radio>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-benar-salah">
                        <div class="card">
                            <div class="card-body py-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="col-md-6">
                                        <h5 class="font-weight-normal">4. Benar atau Salah</h5>
                                    </div>
                                    <div class="col-md-6 d-flex">
                                        <select name="" id="poin" class="form-control mr-3">
                                            <option value="">10 Points</option>
                                        </select>
                                        <select name="" id="time" class="form-control mr-3">
                                            <option value="">30 Seconds</option>
                                        </select>
                                        <button type="button" class="btn btn-outline-secondary mr-3 d-flex align-items-center"><img src="@/assets/images/icons/Edit.svg" alt="" class="mr-1"><span class="text-dark">Edit</span></button>
                                        <button type="button" class="btn btn-outline-secondary"><font-awesome-icon color="#6D75F6" icon="fa-regular fa-trash-can" class="text-dark" /></button>
                                    </div>
                                </div>
                                <div class="d-flex mb-3">
                                    <div class="d-flex w-100">
                                        <p class="font-weight-normal my-auto">Apa yang dimaksud dengan Go Green? Berikan contohnya!</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p class="text-secondary mb-0">Jawaban</p>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="answer">
                                        <table>
                                            <tr>
                                                <td class="pr-5">
                                                    <vs-radio color="danger" class="answer-question-pg justify-content-start" vs-value="luis">Benar</vs-radio>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pr-5">
                                                    <vs-radio color="success" class="answer-question-pg justify-content-start" vs-value="summer">Salah</vs-radio>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import $ from "jquery"

export default {
    layouts: {
        default: '~/views/layouts/empty.vue',
    },
    mounted(){
        this.setSideBar()

        $("section.detail-setup-quiz").parent().addClass('mx-2');
    },
    methods: {
        setSideBar(){
            this.$store.commit('IS_SIDEBAR_ACTIVE', false);
        },
        tambahPertanyaan(){
            this.$router.push({ name: 'Create-Question' })
        }
    }
}
</script>

<style scoped>
    .label {
        font-size: 1.15rem;
    }
    
    .head-card {
        height: 3rem;
    }

    .row .col-3, .row .col-9 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .container-image {
        position: relative;
        width: 25%;
    }
    .container-image:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .container-image img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .select-badge{
        width: auto;
        border: 0;
        border-radius: 15px;
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        background-color: #E5E5E5;
        -moz-appearance:none !important;
        -webkit-appearance: none !important; 
        appearance: none !important;
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .questions { 
        margin-bottom: 1rem;
    }

    .questions .card {
        border-radius: 10px;
    }

    .questions .card .card-body {
        padding-left: 2.25rem;
    }

    .text-none{
        font-size: 14px;
        color: #636363;
    }

    .logo-excel{
        width: 18px;
        height: 18px;
    }
    
    .btn-impor{
        border-radius: 25px;
    }

    .card {
        border-radius: 10px;
    }
</style>